// File: ./src/pages/CallbackPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AUTH_KEYS } from '../constant';
import { isLoggedIn } from '../api/Auth';

function CallbackPage() {
  const navigate = useNavigate();

  useEffect(() => {
    let fragmentParams = new URLSearchParams(window.location.hash.substring(1));

    console.log('Callback URL:', window.location.href);

    // Save all relevant fragment params to localStorage
    let fragString = '';
    AUTH_KEYS.concat(['software']).forEach(key => {
      if (fragmentParams.get(key)) {
        localStorage.setItem(key, fragmentParams.get(key));
      }
      if (localStorage.getItem(key)) {
        fragString += key + '=' + encodeURIComponent(localStorage.getItem(key)) + '&';
      }
    });

    // Now check if user is logged in via /is-logged-in
    (async () => {
      const loggedIn = await isLoggedIn();
      if (loggedIn) {
        // If the URL has the oauth_access_token field, then we need to redirect to /connected#...
        if (fragmentParams.get('oauth_access_token')) {
          navigate('/connected#' + fragString);
        } else {
          // Otherwise it's oauth_code and we need to redirect to /connect#...
          navigate('/connect#' + fragString);
        }

      } else {
        // Just because they are logged in doesn't mean they are connected (we can just store the oauth_code in localStorage and redirect to /signup)
        ////console.log('Not logged in. Redirecting to /signup');
        navigate('/signup');
      }
    })();
  }, [navigate]);

  // Return empty div while redirect
  return <div></div>;
}

export default CallbackPage;
