// File: ./src/utils/Router.js
import { getUser } from '../api/User';

/**
 * Because we need to check user.has_installed from the DB, this function is async.
 */
export async function GetURLRedirect() {
    const user = await getUser();
    let urlParams = new URLSearchParams(window.location.search);
    let urlParamsString = urlParams.toString();
    if (urlParamsString) {
        urlParamsString = '?' + urlParamsString;
    }


    // If no user => not logged in => /signup
    if (!user) {
        //console.log("Redirecting to /signup");
        return '/signup' + urlParamsString;
    }

    const hasInstalled = localStorage.getItem('has_installed') === 'true' || !!user.has_installed;
    const isOauth = /*localStorage.getItem('is_oauth') === 'true' || */localStorage.getItem('auth_options') === 'oauth' || localStorage.getItem('auth_options') === 'either';
    const hasConnected = localStorage.getItem('oauth_access_token') != null || localStorage.getItem('api_key_1') != null;


    if (hasInstalled) {
        if (isOauth && !hasConnected) {
            return '/connect' + urlParamsString;
        } else if (isOauth && hasConnected) {
            let fragUrl = new URLSearchParams();
            Object.keys(localStorage).forEach(key => {
                if (key.startsWith('oauth_')) {
                    fragUrl.set(key, localStorage.getItem(key));
                }
            });
            //console.log("Redirecting to /connected#" + fragUrl.toString());
            return '/connected#' + fragUrl.toString();
        } else {
            return '/complete' + urlParamsString;
        }
    } else {
        return '/install' + urlParamsString;
    }
}
