import React from 'react';
import { XCircle } from '../icons/XCircle';
import { CheckCircle } from '../icons/CheckCircle';
import { apiBaseUrl } from '../../config';
import { Link, useLocation } from 'react-router-dom';
import { FormatMoney, GetSoftwareSlug } from '../../utils/Strings';
import { createCheckoutSession } from '../../api/Billing';


function PlanCard({ plan, data, coupon }) {

  // Dark mode
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDarkMode = searchParams.get('dark_mode') === 'true';
  const software = GetSoftwareSlug();

  if (!data) return null;

  // Parse price and originalPrice as floats
  const price = parseFloat(data.price);
  const originalPrice = parseFloat(data.originalPrice);

  // Use metadata for feature values
  const features = [
    { name: 'Email finder credits', value: data.metadata.credits + " per month" },
    { name: 'Support', value: data.metadata.support },
    { name: 'Team features', value: data.metadata.team ? CheckCircle : XCircle },
    { name: 'Dedicated account manager', value: data.metadata.account_manager ? CheckCircle : XCircle }, ,
    {/* name: "Sync LinkedIn conversations", value: data.metadata.sync_conversations ? CheckCircle : XCircle }, */}
  ];


  const urlParams = new URLSearchParams(window.location.search);
  const stripeCustomerId = urlParams.get('stripe_customer_id');
  const email = urlParams.get('email');
  const isTrialing = urlParams.get('is_trialing');

  const handleGetStarted = async () => {
    try {
      const requestBody = {
        stripe_price_id: data.isYearly ? data.yearly_price_id : data.monthly_price_id,
        stripe_customer_id: stripeCustomerId,
        email: email,
        request_url: window.location.href,
        coupon_code: coupon ? coupon.id : null,
      };
      const result = await createCheckoutSession(requestBody);
      if (result && result.url) {
        window.open(result.url, '_blank');
      } else {
        console.error('Invalid response from checkout endpoint');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };




  let btn = <button
    type="button"
    className="text-white bg-cyan-600 hover:bg-cyan-500 focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-3xl text-md px-6 py-3 text-center inline-flex items-center me-2 mb-2"
    onClick={handleGetStarted}
  >
    Get {plan} plan
    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
    </svg>
  </button>;


  if (isTrialing && plan && plan.toLowerCase() == "free") {
    btn = <button
      type="button"
      className="text-blue-500 border border-blue-500 font-medium rounded-3xl text-md px-6 py-3 text-center inline-flex items-center me-2 mb-2 cursor-no-drop"
    >
      Current plan
    </button>;
  } else if (plan && plan.toLowerCase() == "free") {
    btn = <a
      target='_blank'
      rel="noopener noreferrer"
      href={"/signup" + (software ? "?software=" + software : "")}
      className="text-white bg-cyan-600 hover:bg-cyan-500  focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-3xl text-md px-6 py-3 text-center inline-flex items-center me-2 mb-2"
    >
      Sign up now...
      <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
      </svg>
    </a>
  }




  return (
    <div className={"rounded-2xl p-6  w-full  md:w-1/3 " + (isDarkMode ? "bg-white " : "bg-white border  border-gray-300")}>
      <h2 className="text-xl font-bold capitalize">{plan}</h2>
      <div className="pt-4 pb-8">
        {originalPrice !== price && (
          <div className="flex items-center pb-1">
            <span className="line-through text-2xl text-gray-500 mr-2 block">
              ${originalPrice.toFixed(0)}
            </span>
            <span className="text-green-600 italic font-medium">save {coupon ? coupon.percent_off : 0}%</span>
          </div>
        )}

        {originalPrice == price && (
          <span className="text-xl text-gray-500 mr-2 block">
            Forever
          </span>
        )}
        <span className="text-5xl mr-1.5 font-black">
          ${price.toFixed(0)}
        </span>
        <span className="text-gray-500 text-sm">/ {data.billingPeriod}{data.isYearly && data.originalPrice != 0 && ' ・ billed at ' + FormatMoney(data.originalPrice * 12 * (1.0 - (coupon ? coupon.percent_off / 100 : 0))) + ' / year'}</span>

      </div>
      <ul className="mb-8 space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="flex justify-between">
            <span>{feature.name}</span>
            <span>{feature.value}</span>
          </li>
        ))}
      </ul>
      {btn}
    </div>
  );
}

export default PlanCard;
