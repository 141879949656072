// File: ./src/pages/AuthPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../config';
import { AUTH_KEYS } from '../constant';
import { GetURLRedirect } from '../utils/Router';
import { GetSoftwareSlug } from '../utils/Strings';

function AuthPage() {
  const navigate = useNavigate();

  useEffect(() => {
    let fragmentParams = new URLSearchParams(window.location.hash.substring(1));
    let urlParams = new URLSearchParams(window.location.search);
    const softwareParam = GetSoftwareSlug();
    const reauth = urlParams.get('reauth') === 'true';
    const clear = urlParams.get('clear') === 'true';
    const isInstalled = urlParams.get('installed') === 'true'; // We need to keep this as they might install the extension THEN signup

    //console.log('Auth URL:', window.location.href);

    if (clear) {
      localStorage.removeItem('has_installed');
      localStorage.removeItem('has_onboarded');
      localStorage.removeItem('software');
    }

    if (softwareParam) {
      localStorage.setItem('software', softwareParam);
    }

    if (isInstalled) {
      localStorage.setItem('has_installed', 'true');
    }

    AUTH_KEYS.forEach(key => {
      if (fragmentParams.get(key)) {
        localStorage.setItem(key, fragmentParams.get(key));
      }
    });

    if (reauth) {
      //console.log('Reauth, redirecting to login');
      fetch(`${apiBaseUrl}/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      AUTH_KEYS.forEach(key => {
        localStorage.removeItem(key);
      });
      navigate('/login?' + urlParams.toString());
      return;
    }

    (async () => {
      const redirectUrl = await GetURLRedirect();
      //console.log('redirectUrl', redirectUrl);


      navigate(redirectUrl);
    })();
  }, [navigate]);

  return <div></div>;
}

export default AuthPage;
