// File: ./src/pages/UninstalledPage.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from '../components/header/Logo';
import TrustedBy from '../components/pricing/TrustedBy';
import { LoaderDark } from '../components/icons/LoaderDark';
import { setUserBoolean } from '../api/User';
import { getSoftware } from '../api/Software';
import RightColumn1 from '../components/right-column/RightColumn1';

function UninstalledPage() {
  const title = "Uninstalled - CRM Inputs";
  const description = "The Chrome extension has been uninstalled successfully.";

  const softwareSlug = localStorage.getItem('software');
  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(false);

  console.log('Uninstalled URL:', window.location.href);

  useEffect(() => {
    console.log("Uninstalling");

    // Delete the has_installed flag
    localStorage.removeItem('has_installed');

    setUserBoolean('has_installed', false)
      .catch(err => console.error('Failed to set has_installed=false:', err));


    if (softwareSlug) {
      loadSoftware(softwareSlug);
    }
  }, [softwareSlug]);

  const loadSoftware = async (slug) => {
    setIsLoadingSoftware(true);
    try {
      const data = await getSoftware(slug);
      if (data) {
        setSoftware(data);
      } else {
        console.error('Software not found');
      }
    } catch (error) {
      console.error('Error fetching software:', error);
    } finally {
      setIsLoadingSoftware(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.crminputs.io/uninstalled" />
      </Helmet>

      <div className="min-h-screen flex">
        {/* Left column */}
        <div className="flex-1 flex flex-col justify-between bg-white">
          {isLoadingSoftware ? (
            <div className='flex justify-center items-center h-full'>
              <LoaderDark />
            </div>
          ) : (
            <>
              <div className="py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full">
                <Logo loading={isLoadingSoftware} software={software} />
                <h2 className="mt-10 sm:mt-20 text-3xl font-extrabold text-black">
                  Successfully uninstalled.
                </h2>
                <Link
                  to="/install"
                  className='mt-8 inline-block py-4 px-8 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600
                             hover:bg-crminputs-dark-blue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed'
                >
                  Install again
                </Link>
              </div>
            </>
          )}
        </div>

        {/* Right column */}
        <RightColumn1 />
      </div>
    </>
  );
}

export default UninstalledPage;
