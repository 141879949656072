// File: ./src/pages/ConnectPage.js
import React from 'react';
import TrustedBy from '../pricing/TrustedBy';

function RightColumn1() {

    return (
        <div className='flex-1 flex items-center justify-center bg-gradient-to-br from-crminputs-dark-blue to-crminputs-darkest-blue flex-col px-8 py-16 sm:p-16'>
            <h3 className='text-white text-4xl font-bold pb-10'>Save 4hrs a week in your CRM.</h3>
            <img
                className='max-w-full sm:max-w-sm object-contain'
                src='/img/hero-img.png'
                alt='Chrome Extension for CRMs'
            />
            <h3 className='text-cyan-200 italic text-xl sm:text-2xl font-default pt-8 pb-16'>
                Sync prospects from LinkedIn or XING to your CRM in 1-Click.
            </h3>
            <TrustedBy darkMode={true} />
        </div>

    );
}

export default RightColumn1;
