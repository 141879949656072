// File: ./src/pages/InstalledPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSoftware } from '../api/Software';
import { setUserBoolean } from '../api/User'; // <--- import from api
import Logo from '../components/header/Logo';
import { LoaderDark } from '../components/icons/LoaderDark';
import RightColumn1 from '../components/right-column/RightColumn1';
import SignupProgressBar from '../components/signup/SignupProgressBar';
import { GetURLRedirect } from '../utils/Router';

function InstalledPage() {
  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Instead of fetch -> do:
    setUserBoolean('has_installed', true)
      .catch(err => console.error('Error setting has_installed:', err));
  }, []);

  //console.log('Installed URL:', window.location.href);

  useEffect(() => {
    const softwareSlug = localStorage.getItem('software');
    if (softwareSlug) {
      fetchSoftware(softwareSlug);
    } else {
      navigate('/signup');
    }
  }, [navigate]);

  const fetchSoftware = async (softwareSlug) => {
    //console.log('Fetching software:', softwareSlug);
    setIsLoadingSoftware(true);
    const data = await getSoftware(softwareSlug);
    if (data) {
      setSoftware(data);
      // localStorage.setItem('is_oauth', data.is_oauth === true); 
      localStorage.setItem('auth_options', data.auth_options);
      let redirectUrl = await GetURLRedirect();
      // redirect after 4 seconds
      setTimeout(() => {
        //console.log('Redirecting to:', redirectUrl);
        navigate(redirectUrl);
      }, 4000);
    } else {
      //console.error('Software not found');
      navigate('/signup');
    }
    setIsLoadingSoftware(false);
  };

  return (
    <div className='sm:min-h-screen sm:flex'>
      {/* Left column */}
      <div className='flex-1 flex flex-col justify-between bg-white'>
        {isLoadingSoftware ? (
          <div className='flex justify-center items-center h-full'>
            <LoaderDark />
          </div>
        ) : (
          <>
            <div className='py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full'>
              <Logo loading={isLoadingSoftware} software={software} />
              <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                Successfully installed {software?.app}
              </h1>
              <p className='mt-4'>You will be redirected shortly...</p>
              <div className="p-32">
                <SignupProgressBar currentStep={1} />
              </div>
            </div>
          </>
        )}
      </div>

      {/* Right column */}
      <RightColumn1 />
    </div>
  );
}

export default InstalledPage;
