// SignupPage.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import Logo from '../components/header/Logo';
import { LoaderDark } from '../components/icons/LoaderDark';
import TrustedBy from '../components/pricing/TrustedBy';
import SignupForm from '../components/signup/SignupForm';
import SignupProgressBar from '../components/signup/SignupProgressBar';
import { apiBaseUrl } from '../config';
import { GetSoftwareSlug } from '../utils/Strings';
import { getSoftware } from '../api/Software';
import RightColumn1 from '../components/right-column/RightColumn1';
// Import js-cookie

function SignupPage() {
  const title = 'Sign Up - CRM Inputs';
  const description = 'Create your account and start optimizing your CRM workflow today.';
  const [searchParams] = useSearchParams();

  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(false);

  useEffect(() => {
    const softwareSlug = GetSoftwareSlug();
    if (softwareSlug) {
      localStorage.setItem('software', softwareSlug); // Save software slug to localStorage
      fetchSoftware(softwareSlug);
    }
  }, [searchParams]);

  console.log('Signup URL:', window.location.href);


  const fetchSoftware = async (softwareSlug) => {
    setIsLoadingSoftware(true);
    const data = await getSoftware(softwareSlug);
    if (data && data.slug === softwareSlug) {
      setSoftware(data);
      //  localStorage.setItem('is_oauth', data.is_oauth == true); // depreciated
      localStorage.setItem('auth_options', data.auth_options);
    } else {
      console.error('Software not found');
    }

    setIsLoadingSoftware(false);
  };



  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://app.crminputs.io/signup' />
      </Helmet>


      <div className='sm:min-h-screen sm:flex'>
        {/* Left column */}
        <div className='flex-1 flex flex-col justify-between bg-white'>
          {isLoadingSoftware ? (
            <div className='flex justify-center items-center h-full'>
              <LoaderDark />
            </div>
          ) : (
            <>
              <div className='py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full'>
                <Logo loading={isLoadingSoftware} software={software} />
                <h2 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                  Sign up
                </h2>
                <div className='mt-8'>
                  <SignupForm software={software} />
                </div>
                <div className='pt-32'>
                  <SignupProgressBar
                    currentStep={0} // Always step 1 on the signup page
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {/* Right column */}
        <RightColumn1 />
      </div>
    </>
  );
}

export default SignupPage;
