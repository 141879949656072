// File: ./src/pages/ConnectPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSoftware } from '../api/Software'; // <--- new
import ConnectApiKeyForm from '../components/connect/ConnectApiKeyForm';
import Logo from '../components/header/Logo';
import { LoaderDark } from '../components/icons/LoaderDark';
import RightColumn1 from '../components/right-column/RightColumn1';
import SignupProgressBar from '../components/signup/SignupProgressBar';

function ConnectApiKeyPage() {
  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const softwareSlug = localStorage.getItem('software');
    if (softwareSlug) {
      loadSoftware(softwareSlug);
    } else {
      navigate('/signup');
    }
  }, [navigate]);

  const loadSoftware = async (slug) => {
    setIsLoadingSoftware(true);
    try {
      const data = await getSoftware(slug);
      if (data) {
        setSoftware(data);
      } else {
        //console.error('Software not found');
      }
    } catch (err) {
      //console.error('Error fetching software:', err);
    } finally {
      setIsLoadingSoftware(false);
    }
  };


  return (
    <div className='sm:min-h-screen sm:flex'>
      {/* Left column */}
      <div className='flex-1 flex flex-col justify-between bg-white'>
        {isLoadingSoftware ? (
          <div className='flex justify-center items-center h-full'>
            <LoaderDark />
          </div>
        ) : (
          <>
            <div className='py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full'>
              <Logo loading={isLoadingSoftware} software={software} />
              <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                Connect to {software?.software}
              </h1>

              <p className='mt-4'>Connect your CRM using your API Key so we can start syncing your LinkedIn data.</p>

              <div className='mt-8'>
                <ConnectApiKeyForm software={software} />
              </div>

              {errorMessage && (
                <div className='text-red-500 py-8 rounded relative' role='alert'>
                  <strong className='font-bold'>Error: </strong>
                  <span className='block sm:inline'>{errorMessage}</span>
                </div>
              )}

              {/*software.setup_video_url ? (
                <div className='mt-24'>
                  <h3 className='text-lg text-gray-800 font-semibold mb-4'>Need help? Watch this 2 minute video</h3>
                  <iframe width="560" height="315" src={"https://www.youtube.com/embed/" + GetYoutubeVideoId(software.setup_video_url)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
              ) : null}*/}

              <div className="p-32">
                <SignupProgressBar currentStep={2} />
              </div>
            </div>
          </>
        )}
      </div>

        {/* Right column */}
        <RightColumn1 />
    </div>
  );
}

export default ConnectApiKeyPage;
