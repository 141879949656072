const DefaultLogo = ({ darkMode }) => {
  return (
    <div className="flex items-center flex-col">
      <img 
        src={"/img/logo-dark.png"} 
        alt="CRM Inputs" 
        className="h-8 w-auto" 
      />
      <p className="text-md pt-3 italic text-gray-500">
       CRM Chrome Extensions
      </p>
    </div>
  );
};

export default DefaultLogo;
