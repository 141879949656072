// File: ./src/pages/CompletePage.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SignupProgressBar from '../components/signup/SignupProgressBar';
import TrustedBy from '../components/pricing/TrustedBy';
import Logo from '../components/header/Logo';
import { apiBaseUrl } from '../config';
import { LoaderDark } from '../components/icons/LoaderDark';
import { AUTH_KEYS } from '../constant';
import { setUserBoolean } from '../api/User'; // <--- import
import { getSoftware } from '../api/Software';
import RightColumn1 from '../components/right-column/RightColumn1';

function CompletePage() {
  const title = "Complete Installation - CRM Inputs";
  const description = "Finish setting up your CRM Inputs account and start optimizing your CRM workflow.";

  const [countdown, setCountdown] = useState(5);
  const [countdownFinished, setCountdownFinished] = useState(false);

  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(false);

  const [linkedinUrl, setLinkedinUrl] = useState('https://www.linkedin.com/in/philmcp');

  useEffect(() => {
    const softwareSlug = localStorage.getItem('software');
    if (softwareSlug) {
      fetchSoftware(softwareSlug);
    }

    // Instead of localStorage for has_onboarded
    setUserBoolean('has_onboarded', true).catch(err => console.error(err));

    // Clear local OAuth keys
    AUTH_KEYS.forEach(key => {
      localStorage.removeItem(key);
    });
  }, []);

  const fetchSoftware = async (softwareSlug) => {
    setIsLoadingSoftware(true);
    try {
      const data = await getSoftware(softwareSlug);
      if (data && data.slug === softwareSlug) {
        setSoftware(data);
      } else {
        console.error('Software not found');
      }
    } catch (error) {
      console.error('Error fetching software:', error);
    } finally {
      setIsLoadingSoftware(false);
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCountdownFinished(true);
      handleRedirect();
    }
  }, [countdown]);

  const handleRedirect = () => {
    window.location.href = linkedinUrl;
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.crminputs.io/complete-install" />
      </Helmet>

      <div className="min-h-screen flex">
        {/* Left column */}
        <div className="flex-1 flex flex-col justify-between bg-white">

          {isLoadingSoftware ? (
            <div className='flex justify-center items-center h-full'>
              <LoaderDark />
            </div>
          ) : (
            <>
              <div className="py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full">
                <Logo loading={isLoadingSoftware} software={software} />
                <h2 className="mt-10 sm:mt-20 text-3xl font-extrabold text-black">
                  Setup complete.
                </h2>
                <div className="mt-4">
                  <p className="text-lg pb-8">
                    Redirecting you to LinkedIn so you can start using the Chrome Extension.
                  </p>
                  <button
                    className="w-full flex justify-center py-2.5 px-6 border border-transparent rounded-md shadow-sm text-md font-medium text-black bg-cyan-600 text-white hover:bg-crminputs-darkish-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-crminputs-darkish-blue mb-10"
                    onClick={handleRedirect}
                  >
                    {countdownFinished
                      ? "Click here if you're not redirected"
                      : `Redirecting to LinkedIn in ${countdown}s...`}
                  </button>
                </div>
                <div className="p-32">
                  <SignupProgressBar currentStep={3} />
                </div>
              </div>
            </>
          )}

        </div>

        {/* Right column */}
        <RightColumn1 />
      </div>
    </>
  );
}

export default CompletePage;
