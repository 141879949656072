// ConnectedPage.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TrustedBy from '../components/pricing/TrustedBy';
import { apiBaseUrl } from '../config';
import Logo from '../components/header/Logo';
import { LoaderDark } from '../components/icons/LoaderDark';
import { getSoftware } from '../api/Software';
import { verifySettings } from '../api/Verify';
import RightColumn1 from '../components/right-column/RightColumn1';

function ConnectedPage() {
    const navigate = useNavigate();
    const [software, setSoftware] = useState(null);
    const [isLoadingSoftware, setIsLoadingSoftware] = useState(true);
    const [verified, setVerified] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const [error, setError] = useState(null);
    const authAttempt = localStorage.getItem('auth_attempt');

    //console.log('Connected URL:', window.location.href);

    useEffect(() => {
        const softwareSlug = localStorage.getItem('software');
        if (softwareSlug) {
            fetchSoftware(softwareSlug);
        } else {
            setError('No software found in localStorage');
        }
    }, []);

    const fetchSoftware = async (softwareSlug) => {
        setIsLoadingSoftware(true);
        try {
            const data = await getSoftware(softwareSlug);
            if (data) {
                setSoftware(data);
            } else {
                setError('Software not found');
            }
        } catch (error) {
            setError('Error fetching software: ' + (error.message || error));
        } finally {
            setIsLoadingSoftware(false);
        }
    };

    const verifySettingsCall = async (api1, api2) => {
        //console.log("Verifying...")
        setVerifying(true);
        try {
            let verifyData = await verifySettings({
                api_key_1: api1,
                api_key_2: api2,
                auth_type: localStorage.getItem('auth_attempt')
            });
            //console.log("Verify data", verifyData)
            setVerified(true);
            setTimeout(() => {
                navigate('/complete');
            }, 3000);
        } catch (err) {
            setError('Error verifying settings: ' + (err.message || err));
        } finally {
            setVerifying(false);
        }
    };


    useEffect(() => {
        // We only need to verify the settings if we're using OAuth, as its already been done for API keys
        if (authAttempt === 'oauth') {
            //console.log("OAuth auth attempt")
            const token = localStorage.getItem('oauth_access_token');
            const refreshToken = localStorage.getItem('oauth_refresh_token');
            if (token) verifySettingsCall(token, refreshToken);
        } else if (authAttempt === 'api key') {
            // has api_key_1 in url fragments
            if (window.location.hash.split('api_key_1=').length > 1) {
                setVerified(true);
                setTimeout(() => {
                    navigate('/complete');
                }, 3000);
            }
        }
    }, []);



    const renderContent = () => {
        if (isLoadingSoftware) {
            return (
                <div className='flex justify-center items-center h-full'>
                    <LoaderDark />
                </div>
            );
        }

        if (error) {

            let tryAgainLocation = '/connect';
            if (authAttempt === 'api key') {
                tryAgainLocation = '/connect-api-key';
            }
            return (
                <>
                    <Logo loading={isLoadingSoftware} software={software} />
                    <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                        Connection Error.
                    </h1>
                    <p className='mt-4 text-red-500'>{error}</p>
                    <Link to={tryAgainLocation} className='mt-6 inline-flex justify-center py-4 px-8 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600 hover:bg-crminputs-dark-blue hover:text-white'>
                        Try again
                    </Link>
                </>
            );
        }

        if (verifying && !verified) {
            return (
                <>
                    <Logo loading={isLoadingSoftware} software={software} />
                    <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                        Testing connection.
                    </h1>
                    <p className='mt-4'>Just a sec...</p>
                </>
            );
        }

        if (verified && software) {
            return (
                <>
                    <Logo loading={isLoadingSoftware} software={software} />
                    <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                        Successfully connected.
                    </h1>
                    <p className='mt-4'>
                        You've successfully connected to {software.software}. Redirecting...
                    </p>
                </>
            );
        }

        // Default fallback if we have software but haven't verified yet
        return (
            <>
                <Logo loading={isLoadingSoftware} software={software} />
                <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
                    Connecting...
                </h1>
                <p className='mt-4'>Please wait.</p>
            </>
        );
    };

    return (
        <div className='sm:min-h-screen sm:flex'>
            <div className='flex-1 flex flex-col justify-between bg-white'>


                {isLoadingSoftware ? (
                    <div className='flex justify-center items-center h-full'>
                        <LoaderDark />
                    </div>
                ) :
                    <>
                        <div className='py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full'>
                            {renderContent()}
                        </div>
                    </>
                }

            </div>
            {/* Right column */}
            <RightColumn1 />
        </div>
    );
}

export default ConnectedPage;
