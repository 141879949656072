import React from 'react';

const LinkLogo = ({ app, software, darkMode }) => {
  const mainDivClasses = `inline-block py-4 px-6 border-b-8 border-r-8 font-black italic text-center ${
    darkMode ? 'bg-white border-crminputs-light-blue' : 'bg-crminputs-dark-blue border-crminputs-light-blue'
  }`;

  const mainTextClasses = `text-3xl sm:text-5xl font-bold ${
    darkMode ? 'text-crminputs-dark-blue' : 'text-white'
  }`;

  const subTextClasses = `text-md sm:text-xl italic pt-4 text-center ${
    darkMode ? 'text-gray-200' : 'text-gray-500'
  }`;


  return (
    <div className="text-center">
      <div className={mainDivClasses}>
        <h1 className={mainTextClasses}>{app}</h1>
      </div>
      <div>
        <p className={subTextClasses}>
          Chrome Extension for {software}.
        </p>
      </div>
    </div>
  );
};

export default LinkLogo;
