// File: ./src/components/login/ConnectApiKeyForm.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { verifySettings } from '../../api/Verify';
import InputField from '../fields/InputField';
import { Loader } from '../icons/Loader';

function ConnectApiKeyForm({ software }) {
  // Build dynamic API key fields based on config properties
  const apiKeyFields = [];
  for (let i = 1; i <= 4; i++) {
    const labelKey = `api_key_${i}_label`;
    const placeholderKey = `api_key_${i}_placeholder`;
    if (software && software[labelKey]) {
      apiKeyFields.push({
        name: `api_key_${i}`,
        label: software[labelKey],
        placeholder: software[placeholderKey] || '',
      });
    }
  }

  // Initialize form data with dynamic API key fields and software slug
  const initialFormData = {
    software: software?.slug || '',
  };
  apiKeyFields.forEach(field => {
    initialFormData[field.name] = '';
  });

  const [formData, setFormData] = useState(initialFormData);
  const [errorMessage, setErrorMessage] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    localStorage.setItem('auth_attempt', 'api key');

    //console.log("Form data", formData)

    //console.log("Verifying...")
    setIsVerifying(true);
    setError(null);
    try {

      // Create payload from fields
      let payload = {
        auth_type: 'api key',
      };
      apiKeyFields.forEach(field => {
        payload[field.name] = formData[field.name];
      });

      let verifyData = await verifySettings(payload);
      //console.log("Verify data", verifyData)
      setIsVerified(true);

      // Build fragment parameters from the API key fields
      const fragmentParams = new URLSearchParams();
      apiKeyFields.forEach(field => {
        if (verifyData.api_keys[field.name]) {
          fragmentParams.set(field.name, encodeURIComponent(verifyData.api_keys[field.name]));
        }
      });

      //console.log("Fragment params", fragmentParams.toString())
      // Redirect to /connected with the API key values as fragment parameters
      navigate(`/connected#${fragmentParams.toString()}`);

    } catch (err) {
      setError('Error verifying settings: ' + (err.message || err));
    } finally {
      setIsVerifying(false);
    }


  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {errorMessage && (
        <div className="text-red-500 py-4 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}

      {apiKeyFields.map(field => (
        <InputField
          key={field.name}
          label={software?.software + " " + field.label+":"}
          id={field.name}
          name={field.name}
          type="text"
          placeholder={field.placeholder}
          required
          value={formData[field.name]}
          onChange={handleChange}
        />
      ))}

      <div>
        <div className="flex items-center gap-8">
          <button
            type="submit"
            className="w-auto flex justify-center py-4 px-8 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600 hover:bg-crminputs-dark-blue hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isVerifying}
          >
            {isVerifying ? (
              <>
                <Loader />
                Loading...
              </>
            ) : (
              'Connect...'
            )}
          </button>
        </div>
        <p className='mt-4 text-red-500'>{error}</p>
        <div className="flex items-center gap-8 pt-12">
          <p className="text-md ">
            <Link to="/connect" className="no-underline hover:underline">
              ← Go back
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
}

export default ConnectApiKeyForm;
