// File: ./src/pages/ConnectPage.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoaderDark } from '../components/icons/LoaderDark';
import Logo from '../components/header/Logo';
import SignupProgressBar from '../components/signup/SignupProgressBar';
import { GetURLRedirect } from '../utils/Router';
import { getSoftware } from '../api/Software';
import { apiBaseUrl } from '../config';
import RightColumn1 from '../components/right-column/RightColumn1';

/**
 * ConnectPage
 * - Renders a form for any preconnect fields
 * - On submit, POSTs the preconnect fields to /pre-connect
 * - Then moves on to either the API key flow or OAuth flow
 */
function ConnectPage() {
  const [software, setSoftware] = useState(null);
  const [isLoadingSoftware, setIsLoadingSoftware] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFirstTime, setIsFirstTime] = useState(false);

  // For storing user input for preconnect fields
  const [preconnectData, setPreconnectData] = useState({});

  const navigate = useNavigate();

  /**
   * Load software data on mount.
   */
  useEffect(() => {
    const softwareSlug = localStorage.getItem('software');
    if (softwareSlug) {
      loadSoftware(softwareSlug);
    } else {
      navigate('/signup');
    }

    const urlParams = new URLSearchParams(window.location.search);
    const isFirstTimeVal = urlParams.get('is_first_time');
    if (isFirstTimeVal) {
      setIsFirstTime(isFirstTimeVal);
    }
  }, [navigate]);

  const loadSoftware = async (slug) => {
    setIsLoadingSoftware(true);
    try {
      const data = await getSoftware(slug);
      if (data) {
        setSoftware(data);
        initializePreconnectData(data);
      } else {
        //console.error('Software not found');
      }
    } catch (err) {
      //console.error('Error fetching software:', err);
    } finally {
      setIsLoadingSoftware(false);
    }
  };

  /**
   * Initialize preconnect fields into state:
   * { field.id: "", ... }
   */
  const initializePreconnectData = (software) => {
    if (software?.preconnect_fields?.length) {
      const initialData = {};
      software.preconnect_fields.forEach((field) => {
        initialData[field.id] = '';
      });
      setPreconnectData(initialData);
    }
  };

  /**
   * Handle changes in each preconnect field
   */
  const handlePreconnectFieldChange = (fieldId, value) => {
    setPreconnectData((prev) => ({
      ...prev,
      [fieldId]: value,
    }));
  };

  /**
   * If we already have an oauth_code in localStorage, try to fetch token immediately.
   */
  useEffect(() => {
    const code = localStorage.getItem('oauth_code');
    if (code) {
      fetchAccessToken();
    }
  }, []);

  /**
   * Fetch the access token after OAuth returns with a code
   */
  const fetchAccessToken = async () => {
    setIsConnecting(true);
    const code = localStorage.getItem('oauth_code');
    localStorage.setItem('auth_attempt', 'oauth');
    try {
      const response = await fetch(`${apiBaseUrl}/access-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ oauth_code: code }),
      });
      const data = await response.json();
      if (data.error) {
        setErrorMessage(data.message);
        setIsConnecting(false);
      } else {
        // store returned keys in localStorage
        Object.keys(data.data).forEach((key) => {
          localStorage.setItem('oauth_' + key, data.data[key]);
        });
        const redirect = await GetURLRedirect();
        navigate(redirect);
      }
    } catch (error) {
      //console.error('Error fetching access token:', error);
      setIsConnecting(false);
    }
  };

  /**
   * Submit the "preconnect fields" (if any) to the /pre-connect endpoint.
   */
  const submitPreconnectFields = async () => {
    if (!software?.preconnect_fields?.length) {
      return; // nothing to submit
    }

    // Build an array of { id, value } for each field
    const preconnectArray = software.preconnect_fields.map((field) => ({
      id: field.id,
      value: preconnectData[field.id] || '',
    }));

    // Example payload: { preconnect_fields: [...] }
    const payload = {
      fields: preconnectArray,
    };

    try {
      const resp = await fetch(`${apiBaseUrl}/pre-connect`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(payload),
      });
      const jsonResp = await resp.json();
      if (jsonResp.error) {
        throw new Error(jsonResp.message || 'Error during preconnect');
      }
    } catch (err) {
      throw err;
    }
  };

  /**
   * Proceed with either API-key connect or OAuth connect.
   * (Called after preconnect fields succeed)
   */
  const proceedWithConnection = async () => {
    if (!software) return;

    if (software.auth_options === 'api key') {
      navigate('/connect-api-key');
      return;
    }

    // Otherwise, let's do OAuth (or 'either' but user picks OAuth).
    localStorage.setItem('auth_attempt', 'oauth');
    const clientId = software.oauth_client_id;
    const redirectUri = encodeURIComponent(software.oauth_redirect_uri);
    let authorizationUrl = software.oauth_auth_url.replace('%s', clientId);
    authorizationUrl = authorizationUrl.replace('%s', redirectUri);
    window.open(authorizationUrl, '_blank');
  };

  /**
   * Overall form submission handler, so pressing Enter works.
   */
  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setErrorMessage('');
    setIsConnecting(true);

    // Step 1) If we have preconnect fields, post them first
    try {
      await submitPreconnectFields();
    } catch (err) {
      setErrorMessage(err.message);
      setIsConnecting(false);
      return;
    }

    // Step 2) Move on to connection logic
    try {
      await proceedWithConnection();
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <div className='sm:min-h-screen sm:flex'>
      {/* Left column */}
      <div className='flex-1 flex flex-col justify-between bg-white'>
        {isLoadingSoftware ? (
          <div className='flex justify-center items-center h-full'>
            <LoaderDark />
          </div>
        ) : (
          <div className='py-16 px-6 sm:px-12 md:px-16 lg:px-16 xl:px-16 2xl:px-16 max-w-3xl self-center w-full'>
            <Logo loading={isLoadingSoftware} software={software} />
            <h1 className='mt-10 sm:mt-20 text-3xl font-extrabold text-black'>
              {isFirstTime
                ? `Let's connect to ${software?.software} first...`
                : `Connect to ${software?.software}`}
            </h1>
            <p className='mt-4'>
              Please connect your {software?.software} account so we can start
              syncing your LinkedIn data.
            </p>

            {/* Form wrapper to allow Enter key submission */}
            <form onSubmit={handleSubmit} className='mt-8'>
              {/* If we have preconnect fields, render them */}
              {software?.preconnect_fields?.length > 0 && (
                <div>
                  {software.preconnect_fields.map((field) => (
                    <div key={field.id} className='mb-4'>
                      <label
                        htmlFor={field.id}
                        className='block mb-1 font-semibold'
                      >
                        {field.name}
                      </label>
                      <input
                        id={field.id}
                        type={field.type || 'text'}
                        placeholder={field.placeholder || ''}
                        value={preconnectData[field.id] || ''}
                        required={true}
                        onChange={(e) =>
                          handlePreconnectFieldChange(field.id, e.target.value)
                        }
                        className='border border-gray-300 rounded-lg p-2 w-full'
                      />
                    </div>
                  ))}
                </div>
              )}

              {errorMessage && (
                <div className='mt-4 text-red-600'>{errorMessage}</div>
              )}

              <div className='flex justify-between items-center mt-8'>
                {/* Submit button -> calls handleSubmit */}
                <button
                  type='submit'
                  disabled={isConnecting}
                  className='w-auto flex justify-center py-3 px-6 rounded-lg hover:shadow-xl text-md font-bold text-white bg-cyan-600 hover:bg-crminputs-dark-blue disabled:opacity-50 disabled:cursor-not-allowed'
                >
                  {isConnecting ? 'Connecting...' : 'Connect...'}
                </button>

                <Link
                  to='/complete'
                  className='ml-4 w-auto flex justify-center py-3 px-6 rounded-lg text-md font-bold text-cyan-600 hover:text-black'
                >
                  Skip
                </Link>
              </div>

              {software.auth_options === 'either' && (
                <div className='mt-8'>
                  <Link
                    to='/connect-api-key'
                    className='no-underline hover:underline text-gray-700'
                  >
                    Or connect using API keys instead...
                  </Link>
                </div>
              )}
            </form>

            <div className='p-32'>
              <SignupProgressBar currentStep={2} />
            </div>
          </div>
        )}
      </div>

      {/* Right column */}
      <RightColumn1 />
    </div>
  );
}

export default ConnectPage;
